<template lang="html">
  <div id="contact-page">

    <section class="placed-backg banner-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("contact.title") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("contact.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p>
              {{ $t("contact.description") }}<br>
              {{ $t("contact.description_two") }}
            </p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="placed-backg img-banner-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/banner-2.jpg)' }">
    </section>

    <section class="container oversized-container thanks-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2 class="title">{{ $t("contact.thankyou") }}<br>{{ $t("contact.brand") }}</h2>
        </div>

        <div class="col-lg-7 col-info">
          <p class="mb-3">
            {{ $t("contact.info_one") }}<br>
            <br>
            {{ $t("contact.info_two") }}<br>
            <br>
            {{ $t("contact.info_three") }} <router-link class="f-w-600" to="/privacy-policy">{{ $t("contact.here") }}</router-link>.
          </p>
        </div>
      </div>
    </section>

    <section class="container oversized-container thanks-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <img src="public/images/pages/contact/contact-middle.jpg" style="width: 100%;">
        </div>

        <div class="col-lg-7 col-info">
          <p> {{ $t("contact.text_middle") }}</p>
        </div>
      </div>
    </section>

    <section class="container oversized-container form-section">
      <h2 style="margin-bottom: 5px;color: #002349;
    font-size: 3.2rem;
    font-weight: 400;
    letter-spacing: 7px;
    line-height: 1.1;
    text-align: center;">{{ $t("header.nav.contact_us") }}</h2>

     
<div>
            <!-- Contenedor para el formulario -->
            <div id="hubspot-form"></div>
        </div>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        comments: null,
        type:'Contáctenos',
        purpose:null,
      },
      recaptchaVerified:false,
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      if(this.recaptchaVerified == true){

        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

        var params = this.form;
        var apiURL = tools.url("/api/contact");

        axios.post( apiURL,params )
        .then( (response) => {
          // alert(response.data.response);
          this.makeToast('success', response.data.response, 'Message sent');
          // alert(response.data.response);

          for (let prop in this.form) {
            this.form[prop] = '';
          }
          this.modal.block = false;
          this.$router.push('/thankyou-page');
          //this.modal.icon = "success";
          //this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
        })
        .catch( (error) => {
          // console.log(error.response.data);
          let errors = error.response.data;

          for (let prop in errors) {
            // console.log(errors[prop]);
            this.makeToast('danger', errors[prop], '¡Error!');
          }
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = "Error saving information";
        });
      }else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },

    handleSuccess(response) {
      this.recaptchaVerified = true;
    }
  },
  mounted() {
    if(this.$i18n.locale == 'en' ){
        // Cargar el script de HubSpot
        const script = document.createElement("script");
        script.src = "//js.hsforms.net/forms/embed/v2.js";
        script.charset = "utf-8";
        script.type = "text/javascript";
        script.onload = () => {
            // Crear el formulario después de cargar el script
            if (window.hbspt) {
                window.hbspt.forms.create({
                portalId: "4613600",
                formId: "f6d42332-9b7f-41a6-bd9d-f6625e9741b6",
                target: "#hubspot-form", // Contenedor donde se renderiza el formulario
                });
            }
        };
        document.body.appendChild(script);
    }
    else{
        // Cargar el script de HubSpot
        const script = document.createElement("script");
        script.src = "//js.hsforms.net/forms/embed/v2.js";
        script.charset = "utf-8";
        script.type = "text/javascript";
        script.onload = () => {
        // Crear el formulario después de cargar el script
        if (window.hbspt) {
            window.hbspt.forms.create({
            portalId: "4613600",
            formId: "bbca0728-4685-4421-80b7-32b0d4178a0b",
            target: "#hubspot-form", // Contenedor donde se renderiza el formulario
            });
        }
        };
        document.body.appendChild(script);
    }
   
  },
   //insert the following code for vue-meta to work
   metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.cotactus.meta_title},
        { name: 'og:description', content: this.$root.seoadata.cotactus.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.cotactus.meta_keywords},
      ]
    }
  },
}
</script>
