<template lang="html">
  <div id="properties-page">

    <section class="placed-backg py-0 banner-section-s1" id="first-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("result.title_sale") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("result.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p>{{ $t("result.title_description_sale") }}</p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <Searchbar1Component></Searchbar1Component>

    <section class="properties-section" v-bind:class="{ 'only-list' : displayAs == 'list' }">
      <div class="container oversized-container">
        <div class="row">
          <!-- Map -->
          <!-- https://www.npmjs.com/package/vue2-google-maps -->
          <!-- https://xkjyeah.github.io/vue-google-maps/autoapi.html -->
          <div class="col-12 col-properties only-for-mobile-map">
            <div class="row row-top">
              <div class="col-12 col-filters">
                <div class="col col-display">
                  <a class="link-display list" v-bind:class="{ 'active' : displayAs == 'list' }" @click="displayAs = 'list'">{{ $t("result.filter_list") }}</a>
                  <a class="link-display map"  v-bind:class="{ 'active' : displayAs == 'map' }" @click="displayAs = 'map'">{{ $t("result.filter_map") }}</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-7 col-xl-6 col-map" v-if="displayAs == 'map'">
            <div class="box-map" id="box-map-container">
              <GmapMap
                :center="mapCenter"
                :zoom="zomm"
                ref="mapRef"
                @bounds_changed="updateMarkerVisibility"
                v-if="showMap">
                <GmapMarker
                  :key="'marker-'+mInx"
                  v-for="(m, mInx) in propertiesAll"
                  :position="{lat: m.lat, lng: m.lng}"
                  :title="m.name"
                  :clickable="true"
                  :draggable="false"
                  :icon="{ url: 'public/images/shared/map-icon.svg'}"
                  @mouseover="markAction(m)"
                  @mouseout="windowOpen = false"
                  @click="clickedMark(m)"
                  v-if="m.lat != 0 && m.lng != 0"
                />

                <gmap-info-window
                  @closeclick="windowOpen = false"
                  :opened="windowOpen"
                  :position=" {lat: gmapInfo.lat, lng: gmapInfo.lng}"
                  :options="{
                    pixelOffset: {
                      width: 0, height: -35
                    }
                  }"
                >
                  <router-link class="gmap-box-info" :to="('/property/'+gmapInfo.custom_url)">
                    <div class="box-info">
                      <div class="photo">
                        <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+gmapInfo.imageUrl+')' }"></div>
                      </div>

                      <div v-if="gmapInfo.price_request == 0">
                      <h5 class="price" v-if="gmapInfo.price > 0"> $ {{ formatNum(gmapInfo.price) }} MXN</h5>
                        <h5 class="price" v-else>-</h5>
                        
                      <h5 class="price" v-if="gmapInfo.price_usd > 0"> $ {{ formatNum(gmapInfo.price_usd) }} USD</h5>
                        <h5 class="price" v-else>-</h5>
                    </div>
                    <div v-else>
                      <h5 class="price" v-if="$i18n.locale == 'es'">Precio bajo solicitud</h5>
                      <h5 class="price" v-if="$i18n.locale == 'en'">Price upon request</h5>
                    </div>

                      <div class="box-descr">
                        {{ gmapInfo.content  }}
                      </div>
                    </div>

                    <div class="box-bottom">
                      <span v-if="$i18n.locale == 'en'">{{ gmapInfo.name }}</span>
                      <span v-if="$i18n.locale == 'es'">{{ gmapInfo.name_es }}</span><br> {{ gmapInfo.zipcode }}
                    </div>
                  </router-link>
                </gmap-info-window>
              </GmapMap>
              <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15007912.728453133!2d-111.64064161919171!3d23.31426414371436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84043a3b88685353%3A0xed64b4be6b099811!2zTcOpeGljbw!5e0!3m2!1ses!2smx!4v1669670738643!5m2!1ses!2smx" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
            </div>
          </div>
          <!--  -->

          <!-- Properties -->
          <div class="col-lg-5 col-xl-6 col-properties">
            <div class="row row-top">
              <div class="col-12 col-top">
                <div class="col-7 col-title">
                  <h5>
                    {{ $t("result.properties") }}
                    <span v-if="!this.$route.query.buyrent"></span>
                    <span v-if="this.$route.query.buyrent == 1"> {{ $t("result.properties_forsale") }}</span>
                    <span v-if="this.$route.query.buyrent == 2"> {{ $t("result.properties_forrent") }}</span>
                  </h5>
                </div>

                <div class="col-5 col-button">
                  <router-link class="btn btn-sm btn-s1 blue btn-bg" to="/sell-with-us">{{ $t("result.sellaproperty") }}</router-link>
                </div>

                <div class="col-12 col-text">
                  <p>{{ $t("result.subtitleproperties") }}</p>
                </div>
              </div>

              <div class="col-12 col-filters">
                <div class="col col-order">
                  <b-form-group
                    :label='$t("result.filter")'>
                    <b-form-select v-model="form.order">
                      <b-form-select-option value="1">{{ $t("result.filter_pricelow") }}</b-form-select-option>
                      <b-form-select-option value="2">{{ $t("result.filter_pricehigh") }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col col-display">
                  <a class="link-display list" v-bind:class="{ 'active' : displayAs == 'list' }" @click="displayAs = 'list'">{{ $t("result.filter_list") }}</a>
                  <a class="link-display map"  v-bind:class="{ 'active' : displayAs == 'map' }" @click="displayAs = 'map'">{{ $t("result.filter_map") }}</a>
                </div>
              </div>
            </div>

            <div class="row row-middle">
              <div class="col-12 col-results-info">
                <h5>{{properties.length}} {{ $t("result.filter_propeties") }}</h5>
              </div>

              <div class="col-md-6 col-lg-12 col-xl-6 col-property-sample-1" v-for="(prop, prInx) in properties" :key="'prInx-'+prInx" @mouseover="handleMouseOver(prop)" @mouseout="windowOpen = false">
                <router-link class="box-link" :to="'/property/'+prop.custom_url" target="_blank">
                 <div class="placed-backg box-image">
                    <LazyBackground :src="prop.imageUrl">
                        <img src="public/images/pages/properties/property.png">
                    </LazyBackground>
                  </div> 
                  

                  <div class="box-text">
                    <h5 class="name" v-if="$i18n.locale == 'en'">{{ prop.name }}<br><br>{{ prop.state }}, {{ prop.city }}</h5>
                    <h5 class="name" v-if="$i18n.locale == 'es'">{{ prop.name_es }}<br><br>{{ prop.state }}, {{ prop.city }}</h5>
                   
                    <div v-if="prop.price_request == 0">

                      <!-- <div v-if="$root.currency == 'usd'">
                          <h6 class="price" v-if="prop.price_usd > 0"> $ {{ formatNum(prop.price_usd) }} USD</h6>
                          <h6 class="price" v-else> $ {{ formatNum(prop.price_mxn) }} MXN</h6>
                      </div>
                      <div v-if="$root.currency == 'mxn'">
                        <h6 class="price" v-if="prop.price_mxn > 0"> $ {{ formatNum(prop.price_mxn) }} MXN</h6>
                          <h6 class="price" v-else> $ {{ formatNum(prop.price_usd) }} USD</h6>
                      </div> -->
                      <h6 class="price" v-if="prop.price > 0"> $ {{ formatNum(prop.price) }} MXN</h6>
                        <h6 class="price" v-else>-</h6>

                        <h6 class="price" v-if="prop.price_usd > 0"> $ {{ formatNum(prop.price_usd) }} USD</h6>
                        <h6 class="price" v-else>-</h6>
                    </div>
                    <div v-else>
                      <h6 class="price" v-if="$i18n.locale == 'es'">Precio bajo solicitud</h6>
                      <h6 class="price" v-if="$i18n.locale == 'en'">Price upon request</h6>
                    </div>

                    <div class="box-prop-info">
                      <span v-if="prop.bedrooms > 0" style="padding-right:0px">{{ prop.bedrooms}} {{ $t("result.result_bed") }} , </span>
                    <span style="padding-right: 0px;padding-left:0px" v-if="prop.toilets > 0">{{ prop.toilets}} {{ $t("result.result_bath") }}</span>
                    <span style="padding-left: 0px;" v-if="prop.toilets_partial != null && prop.toilets_partial != 0">| {{prop.toilets_partial}} {{ $t("result.result_bath_partial") }}</span>

                    
                  </div>
                  </div>
                </router-link>
              </div>
            </div>

            <!-- <div class="row" v-if="properties.length > 0">
                <div class="col-12 text-center">
                  <div class="d-inline-block mx-0 col-pagination-sample-1">
                    <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                  </div>
                </div>
            </div> -->
          </div>
          <!--  -->
        </div>
      </div>
    </section>

    <b-modal ref="modal-preview" modal-class="modal-preview" title="" centered hide-footer>
      <router-link
        :to="'/property/'+modalPropInfo.url"  target="_blank"
        class="placed-backg box-image"
        v-bind:style="{ backgroundImage: 'url('+modalPropInfo.imageUrl+')' }">
      </router-link>

      <router-link class="box-text" :to="'/property/'+modalPropInfo.url"  target="_blank">
        <div class="b-info">
          <h5 class="price" v-if="modalPropInfo.price > 0">$ {{ formatNum(modalPropInfo.price) }} MXN</h5>
          <h5 class="price" v-else>-</h5>
          <h5 class="price" v-if="modalPropInfo.price_usd > 0">$ {{ formatNum(modalPropInfo.price_usd) }} USD</h5>
          <h5 class="price" v-else>-</h5>
        </div>

        <div class="b-name">
          <h5 v-if="$i18n.locale == 'es'">{{ modalPropInfo.name_es }}</h5>
          <h5 v-else>{{ modalPropInfo.name }}</h5>
        </div>
        <div class="btn-visit">
          <span>READ MORE</span>
        </div>
      </router-link>
    </b-modal>


  </div>
</template>

<script>
import Searchbar1Component from './searchbar-1-component.vue';
import LazyBackground from '../shared/LazyBackground.vue';
export default {
  components: {
    Searchbar1Component,
    LazyBackground
  },

  data(){
    return {
      // == map scroll values ==
      showMap: false, // tru, false para mostrar el mapa (el plugin)
      windowOpen: false, // true, false para mostrar la ventana de los markers
      mapCenter: { lat:20.7285726, lng:-105.340087 }, // Centro del mapa
      gmapInfo: { // Obj para la ventana de los markers
        lat: 0, 
        lng: 0,
        content: ``
      },

      displayAs: 'map', // map & list
      scrollY: 0,   // Scroll Y al bajar/subir (vertical)
      positionY: 0, // Posición Y para un elemento (vertical)

      viewportW: 0, // Anchura de *La ventana*
      viewportH: 0, // Altura de *La ventana*
      headerH: 0,   // Altura del *Header*
      searchBarH: 0,// Altura de *La barra de búsqueda*
      firstSectH: 0,// Altura de la *primer seccion donde dice "sale" o "rent"
      mapH: 0,      // Altura del *Mapa*
      // == ==

      markers: [
        { id: 5, price: '822.00', name: 'Margarita valley', position: { lat: 20.6696524, lng: -103.3638994 }, content: `A very very short description` },
        { id: 6, price: '700.00', name: 'Villa de los angeles', position: { lat: 19.317365, lng: -99.2211063 }, content: `A very very short description` },
      ],

      properties: [],

      modalPropInfo: {
        url: null,
        imageUrl: null,
        price_usd: 0,
        name: null,
        name_es: null,
      },

      form: {
        order: null,
      },
      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_properties:1,
      },
      propertiesAll:[],
      zomm:11,
      fitboundsready:false,
    }
  },

  methods: {
    handleScroll(event){
        this.scrollY = window.scrollY;
        // console.log(this.scrollY);
        this.viewportW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        this.viewportH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        var header = document.getElementById( 'header' );
        if(header){
            this.headerH = header.offsetHeight;
        }
        else{
            this.headerH = 0;
        }
        
        var searchBar = document.getElementById( 'search-bar' );
        if(searchBar){
            this.searchBarH = searchBar.offsetHeight;
        }
        else{
            this.searchBarH = 0;
        }

        var fisrtsection = document.getElementById( 'first-section' );
        if(fisrtsection){
            this.firstSectH = fisrtsection.offsetHeight;
        }
        else{
            this.firstSectH = 0;
        }
        let tempMapH = this.viewportH - (this.headerH + this.searchBarH + this.firstSectH);

        // if(this.viewportW >= 992 && this.displayAs == 'map') { // El mapa solo se mostrará en escritorio y si está seleccionado "map"
        if(this.displayAs == 'map') { // El mapa solo se mostrará en escritorio y si está seleccionado "map"
            if(this.scrollY < (this.searchBarH + this.firstSectH)){ // Si el scroll-Y es menor a la altura del Buscador y de la Primer Sección (el Buscador y de la Primer Sección siempre debe estar visible y nunca ser tapado por el mapa)
            this.positionY = (this.headerH + this.searchBarH + this.firstSectH) - this.scrollY;
            this.mapH = tempMapH + scrollY;
            }else {
            this.positionY = this.headerH;
            this.mapH = this.viewportH - this.headerH;
            }

            var box_map = document.getElementById('box-map-container');
            if(box_map){
            document.getElementById('box-map-container').setAttribute('style','top:'+this.positionY+'px;height:'+this.mapH+'px;');
            }

        }else {
            this.displayAs = 'list';
        }
    },

    markAction(val){
      if(this.viewportW > 992) {
        // console.log(this);
        this.gmapInfo = val;
        this.windowOpen = true;
      }
    },

    getRouteParams() {
        this.$root.modal_loading = true;
        this.$root.searchForm.destination = this.$route.query.destination;
        this.$root.searchForm.order_price = this.form.order;
        this.$root.searchForm.unit = this.$root.unit;
        this.$root.searchForm.currency = this.$root.currency;
        if(this.$route.query.propType){
            this.$root.searchForm.propType = this.$route.query.propType;
        }
        else{
            this.$root.searchForm.propType = null;
        }
        if(this.$route.query.buyRent){
            this.$root.searchForm.buyrent = this.$route.query.buyRent;
        }
        else{
            this.$root.searchForm.buyrent = null;
        }
        
        if(this.$route.query.location){
            this.$root.searchForm.location = this.$route.query.location;
        }
        else{
            this.$root.searchForm.location = null;
        }
        
        if (this.$route.query.minprice !== null && this.$route.query.minprice !== undefined && this.$route.query.maxprice !== null && this.$route.query.maxprice !== undefined){
          this.$root.searchForm.price[0] = parseInt(this.$route.query.minprice);
            this.$root.searchForm.price[1] = parseInt(this.$route.query.maxprice);
        }
        else{
            this.$root.searchForm.price = [];
        }
        if(this.$route.query.lifestyle){
            this.$root.searchForm.lifestyle = this.$route.query.lifestyle;
        }
        else{
            this.$root.searchForm.lifestyle = null;
        }
        if (this.$route.query.minbedrooms !== null && this.$route.query.minbedrooms !== undefined && this.$route.query.maxbedrooms !== null && this.$route.query.maxbedrooms !== undefined){
          this.$root.searchForm.bedrooms[0] = parseInt(this.$route.query.minbedrooms);
            this.$root.searchForm.bedrooms[1] = parseInt(this.$route.query.maxbedrooms);
        }
        else{
            this.$root.searchForm.bedrooms = [];
        }
        if (this.$route.query.mintoilets !== null && this.$route.query.mintoilets !== undefined && this.$route.query.maxtoilets !== null && this.$route.query.maxtoilets !== undefined){
          this.$root.searchForm.toilets[0] = parseInt(this.$route.query.mintoilets);
            this.$root.searchForm.toilets[1] = parseInt(this.$route.query.maxtoilets);
        }
        else{
            this.$root.searchForm.toilets = [];
        }
        
        if(this.$route.query.keywords){
            this.$root.searchForm.keywords = this.$route.query.keywords;
        }
        else{
            this.$root.searchForm.keywords = null;
        }
        
        if (this.$route.query.mininterior !== null && this.$route.query.mininterior !== undefined && this.$route.query.maxmininterior !== null && this.$route.query.maxmininterior !== undefined){
          this.$root.searchForm.interior[0] = parseInt(this.$route.query.mininterior);
            this.$root.searchForm.interior[1] = parseInt(this.$route.query.maxmininterior);
        }
        else{
            this.$root.searchForm.interior = [];
        }
        if (this.$route.query.minexterior !== null && this.$route.query.minexterior !== undefined && this.$route.query.maxminexterior !== null && this.$route.query.maxminexterior !== undefined){
          this.$root.searchForm.exterior[0] = parseInt(this.$route.query.minexterior);
            this.$root.searchForm.exterior[1] = parseInt(this.$route.query.maxminexterior);
        }
        else{
            this.$root.searchForm.exterior = [];
        }

        axios.post(tools.url("/api/properties?page=" + this.pagination.currentpage),this.$root.searchForm).then((response)=>{
            this.propertiesAll = response.data;

            if (window.innerWidth <= 768) {
                this.ismovil = true;
                this.properties = response.data;
            }

            var self = this;
            setTimeout(()=> {
                self.showMap = true;
                
            }, 700);

            setTimeout(function(){
                self.zomm = 7;
                self.$root.modal_loading = false;
                self.updateMarkerVisibility();
            }, 800);

        });
        

    },
    /*getAllLocations(){
        axios.post(tools.url("/api/propertiesall"),this.$root.searchForm).then((response)=>{
            this.propertiesAll = response.data;
        });
    },*/

    getProperties() { // Obtener la info de la BD
        this.showMap = false;
        this.getRouteParams(); // Antes, obtener la info de la ruta
    },

    formatNum(num) {
      let x = Intl.NumberFormat("en-US").format(num);
      return x;
    },

    linkGen(pageNum) {
         return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    updateMarkerVisibility(){
      if(this.showMap == true){
          const mapRef = this.$refs.mapRef.$mapObject;
          const bounds = mapRef.getBounds();
          const visibleMarkers = this.propertiesAll.filter((marker) =>
            bounds.contains(new google.maps.LatLng(marker.lat, marker.lng))
          );

          this.properties = visibleMarkers;
          //this.fitBounds();
      }
    },
    fitBounds() {
      if(this.fitboundsready == false){
          const bounds = new google.maps.LatLngBounds();

          this.properties.forEach((location) => {
            bounds.extend(new google.maps.LatLng(location.lat, location.lng));
          });

          const map = this.$refs.mapRef.$mapObject;
          map.fitBounds(bounds);
          
        
          map.setZoom(map.getZoom() - 2); // Ajusta el número según sea necesario
          this.fitboundsready = true;
          this.$root.modal_loading = false;
      }
    },

    clickedMark(propInfo) {
      if(this.viewportW > 992) {
        //this.$router.push('/property/'+propInfo.custom_url);
        window.open('/#/property/'+propInfo.custom_url,'_blank');
      }else {
        this.modalPropInfo = {
          url: propInfo.custom_url,
          imageUrl: propInfo.imageUrl,
          price_usd: propInfo.price_usd,
          name: propInfo.name,
          name_es: propInfo.name_es,
        };

        this.$refs['modal-preview'].show();
      }
    },
    handleMouseOver(id) {
        this.markAction(id);
    },

  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  },

  watch: {
    displayAs(newVal, oldVal) {
      if(newVal == 'map') {
        setTimeout(()=>{
          this.handleScroll();
        }, 300);
      }
    },

    '$route' (to, from){

      if(this.$route.query.page){
        this.pagination.currentpage = this.$route.query.page;
        }
        else{
            this.pagination.currentpage = 1;
        }
        this.getProperties();
    },

    'form.order':function(val){
        /*if (this.form.order == 1) {
          var sortedproperties = this.properties.sort(
            (p1, p2) => (p1.price > p2.price) ? 1 : (p1.price < p2.price) ? -1 : 0);
        }
        else if(this.form.order == 2){
            var sortedproperties = this.properties.sort(
            (p1, p2) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);
        }
        this.properties = sortedproperties;*/
        this.getRouteParams();
    },
    /*'$route.query.page':function(val){
        if (val) {
            this.pagination.currentpage = val;
        }
        else{
            this.pagination.currentpage = 1;
        }
        this.getRouteParams();

    },*/
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.sale.meta_title},
        { name: 'og:description', content: this.$root.seoadata.sale.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.sale.meta_keywords},
      ]
    }
  },
    beforeMount() {
        this.$root.searchForm.location = null;
        this.$root.searchForm.propType = null;
        this.$root.searchForm.keywords = null;
        this.$root.searchForm.buyRent = 1;
        this.$root.searchForm.for = null;
        this.$root.searchForm.type = [];
        this.$root.searchForm.price = [];
        this.$root.searchForm.acreage = [];
        this.$root.searchForm.lifestyle = null;
        this.$root.searchForm.destination = null;
        this.$root.searchForm.lifestyle = null;

        this.$root.searchForm.buyRent = 2; // Mandar antes el valor 1 (sale) para recibir solo propiedades en venta


    },

    mounted(){
        if(this.$route.query.page){
            this.pagination.currentpage = this.$route.query.page;
        }
        setTimeout(()=>{
            this.handleScroll();
        }, 600);
        //this.getAllLocations();
        this.getProperties();


        var self = this;

       
        
        if (window.innerWidth <= 768) {
            this.displayAs = 'list';
        }
    },

  destroyed () {
    window.removeEventListener('scroll', this.$root.handleScroll);
    window.removeEventListener('resize', this.$root.handleScroll);
  }
}
</script>
