<template lang="html">
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-footer col-logo">
          <router-link to="/">
            <img src="public/images/logo-white.svg">
          </router-link>
        </div>

        <div class="col-12 col-footer col-contact">
          <span class="tel"><i class="fas fa-phone-alt icon"></i> +52 322 409 5609</span>
          <a class="email" href="mailto:info@puertovallartasir.com"><i class="fas fa-envelope icon"></i> info@puertovallartasir.com</a>

          <span class="networks">
            <a target="_blank" href="https://www.facebook.com/PuertoVallartaSothebysRealty"><i class="fab fa-facebook-f"></i></a>
            <a target="_blank" href="https://www.instagram.com/puertovallartasothebysrealty"><i class="fab fa-instagram"></i></a>
            <a target="_blank" href="https://www.linkedin.com/company/puertovallartasothebysrealty"><i class="fab fa-linkedin-in"></i></a>
            <a target="_blank" href="https://www.youtube.com/@PuertoVallartaSIR"><i class="fab fa-youtube"></i></a>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-lg-3 col-footer col-links">
          <h6 class="title">{{ $t("footer.properties") }}</h6>

          <router-link to="/properties">{{ $t("footer.all_listing") }}</router-link><br />
          <router-link to="/properties/rent">{{ $t("footer.reting") }}</router-link><br />
          <router-link to="/properties/sale">{{ $t("footer.selling") }}</router-link><br />
        </div>

        <div class="col-6 col-lg-3 col-footer col-links">
          <h6 class="title">{{ $t("footer.discover") }}</h6>

          <router-link to="/destinations">{{ $t("footer.destinations") }}</router-link><br />
          <router-link to="/lifestyles">{{ $t("footer.lifestyle") }}</router-link><br />
          <router-link to="/projects">{{ $t("footer.projects") }}</router-link>
        </div>

        <div class="col-6 col-lg-3 col-footer col-links">
          <h6 class="title">{{ $t("footer.about_us") }}</h6>

          <router-link to="/sell-with-us">{{ $t("footer.selling_a_home") }}</router-link><br />
          <router-link to="/about-sothebys-mexico">{{ $t("footer.about_sothebys_mexico") }}</router-link>
        </div>

        <div class="col-6 col-lg-3 col-footer col-links">
          <h6 class="title">{{ $t("footer.more_links") }}</h6>

          <router-link to="/agents">{{ $t("footer.agents") }}</router-link><br />
          <router-link to="/contact">{{ $t("footer.contact_us") }}</router-link><br />
          <router-link to="/blog">{{ $t("footer.our_blog") }}</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
}
</script>
