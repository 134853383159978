<template lang="html">
  <header id="header" v-bind:class="{ 'style-2' : specialHeader, 'w-bg-blue' : headerWithBg }">
    <div class="header-content" ref="headerContent">
      <b-navbar toggleable="lg" type="light" variant="light" id="header-navbar">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img class="normal" src="public/images/logo.svg" alt="Puerto Vallarta Sotheby’s">
            <img class="white" src="public/images/logo-white.svg" alt="Puerto Vallarta Sotheby’s">
          </b-navbar-brand>

          <div class="box-extra-options">
            <a class="btn-extra" @click="$root.isSearchActive = !$root.isSearchActive">
              <i class="far fa-search"></i>
            </a>
            <a class="btn-extra" @click="$root.isPrefActive = !$root.isPrefActive">
              <i class="far fa-cog"></i>
            </a>
          </div>

          <b-navbar-toggle target="nav-collapse"><i class="far fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mx-auto">
              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>{{ $t("header.nav.properties") }}</span>
                </template>
                <!-- <b-dropdown-item :to="{ name: 'propsPage' }">General Listing</b-dropdown-item> -->
                <b-dropdown-item :to="{ name: 'propsSalePage' }">{{ $t("header.nav.properties_for_sale") }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'propsRentPage' }">{{ $t("header.nav.properties_for_rent") }}</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>{{ $t("header.nav.discover") }}</span>
                </template>
                <b-dropdown-item to="/destinations">{{ $t("header.nav.destinations") }}</b-dropdown-item>
                <b-dropdown-item to="/lifestyles">{{ $t("header.nav.lifestyles") }}</b-dropdown-item>
                <b-dropdown-item to="/projects">{{ $t("header.nav.projects") }}</b-dropdown-item>
                <b-dropdown-item to="/blog">{{ $t("header.nav.blog") }}</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>{{ $t("header.nav.about_us") }}</span>
                </template>
                <b-dropdown-item to="/sell-with-us">{{ $t("header.nav.sell_with_us") }}</b-dropdown-item>
                <b-dropdown-item to="/about-sothebys-mexico">{{ $t("header.nav.about_sothebys_mexico") }}</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item" to="/agents">{{ $t("header.nav.agents") }}</b-nav-item>
              <b-nav-item class="simple-item" to="/contact">{{ $t("header.nav.contact_us") }}</b-nav-item>

              <li class="nav-item simple-item search-item">
                <a class="nav-link" @click="$root.isSearchActive = !$root.isSearchActive"><i class="fal fa-search"></i> <span class="d-lg-none ml-1">Search</span></a>
              </li>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
              <li class="nav-item simple-item menu-item mt-2" @click="$root.isPrefActive = !$root.isPrefActive">
                <a class="desktop nav-link">Menu <i class="icon"></i></a>
                <a class="mobile nav-link"><i class="icon"></i> Preferences </a>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <sweet-modal ref="modalloading" blocking hide-close-button>
      <div class="fa-3x" ><i class="fas fa-spinner fa-pulse"></i></div><br/>

      <p v-if="$i18n.locale == 'es'">Cargando ...</p>
      <p v-if="$i18n.locale == 'en'">Loading ...</p>
    </sweet-modal>

    <!-- Preferences Menu -->
    <preferences-component></preferences-component>
    <!--  -->

    <!-- Search -->
    <search-component></search-component>
    <!--  -->

  </header>
</template>

<script>
import preferencesComponent from './preferences-component.vue'
import searchComponent from './search-component.vue'
export default {
  components: {
    preferencesComponent, searchComponent
  },

  data(){
    return{
      scrollY: 0, // Scroll Y al bajar/subir (vertical)
      headerWithBg: false, // Si el header en modo "fixed" debe tener fondo de color
      specialHeader: false,
    }
  },

  methods: {
    getHeaderHeight() { // Obtener el alto del contenido del header y aplicarlo al id "#header"
      // let headerH = this.$refs.headerContent.clientHeight;
      let headerH = document.getElementById('header-navbar').offsetHeight;
      document.getElementById('header').setAttribute('style','height:'+headerH+'px;');
    },

    fixedHeader(event){
      if(this.specialHeader) {
        this.scrollY = window.scrollY;
        let viewportW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let viewportH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        this.headerWithBg = (scrollY > (viewportH / 2)) ? true : false;
      }
    },

    getRouteName() { // Obtener el nombre de la ruta para aplicar una clase CSS especial al header en ciertas paginas
      let pageName = this.$route.name;

      if(pageName == 'homePage'){
        this.specialHeader = true;
      }else {
        this.specialHeader = false;
      }
    },

    onSubmitSearch(event) { // Buscar
      event.preventDefault();
    },
  },

  watch: {
    '$route' (to, from) {
      this.getRouteName();

        if (this.$route.path.includes('properties')){

		    }
		    else{
            if (this.$root.searchForm.price[0] !== undefined) {
              this.$root.searchForm.price[0] = null;
            }
            if (this.$root.searchForm.price[0] !== undefined) {
              this.$root.searchForm.price[1] = null;
            }

            if (this.$root.searchForm.interior[0] !== undefined) {
                this.$root.searchForm.interior[0] = null;
            }
            if (this.$root.searchForm.interior[0] !== undefined) {
                this.$root.searchForm.interior[1] = null;
            }

            if (this.$root.searchForm.exterior[0] !== undefined) {
              this.$root.searchForm.exterior[0] = null;
            }
            if (this.$root.searchForm.exterior[0] !== undefined) {
              this.$root.searchForm.exterior[1] = null;
            }

            if (this.$root.searchForm.bedrooms[0] !== undefined) {
              this.$root.searchForm.bedrooms[0] = null;
            }
            if (this.$root.searchForm.bedrooms[0] !== undefined) {
              this.$root.searchForm.bedrooms[1] = null;
            }

            if (this.$root.searchForm.toilets[0] !== undefined) {
              this.$root.searchForm.toilets[0] = null;
            }
            if (this.$root.searchForm.toilets[0] !== undefined) {
              this.$root.searchForm.toilets[1] = null;
            }


            this.$root.searchForm.propType = null;
            this.$root.searchForm.price = [];
            this.$root.searchForm.lifestyle = null;
            this.$root.searchForm.bedrooms = null;
            this.$root.searchForm.toilets = null;
            this.$root.searchForm.keywords = null;
            this.$root.searchForm.interior = [];
            this.$root.searchForm.exterior = [];
            this.$root.searchForm.bedrooms = [];
            this.$root.searchForm.toilets = [];
		    }
    },
    '$root.modal_loading':function(){
        if(this.$root.modal_loading == true){
          this.$refs.modalloading.open();
        }
        else{
          this.$refs.modalloading.close();
        }
    }
  },

  beforeMount() {
    this.getRouteName();
  },

  // == ==
  mounted(){
    this.getHeaderHeight();
  },

  created () {
    window.addEventListener('resize', this.getHeaderHeight);
    window.addEventListener('scroll', this.fixedHeader);
    window.addEventListener('resize', this.fixedHeader);
  },

  destroyed () {
    window.removeEventListener('resize', this.$root.getHeaderHeight);
    window.addEventListener('scroll', this.fixedHeader);
    window.addEventListener('resize', this.fixedHeader);
  }
  // == ==
}
</script>
